export enum PageTypes {
	Create = 'CREATE',
	Find = 'FIND',
	View = 'VIEW',
	ViewAll = 'VIEWALL',
	Stats = 'STATS',
	Subscription = 'SUBSCRIPTION',
	Settings = 'SETTINGS',
	Tutorial = 'TUTORIAL'
}
