import * as React from 'react';
import { SupabaseClient } from '@supabase/supabase-js';

type SubscriptionProps = {
  setCode: Function;
  setPage: Function;
  code: string;
  supabaseClient: SupabaseClient;
};

function Subscription(props: SubscriptionProps) {
  //TODO Subscription Page
   return (
     <>
       Subscriptions coming soon
     </>
  )
}

export default Subscription;
