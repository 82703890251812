export const Titles = {
	'CREATE': 'Create Gift Code',
	'FIND': 'Find Gift Code',
	'VIEW': 'View Gift Code',
	'VIEWALL': 'All Gift Codes',
	'STATS': 'Gift Code Statistics',
	'SUBSCRIPTION': 'Subscription',
	'SETTINGS': 'Settings',
	'TUTORIAL': 'Tutorial'
}
