import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {TextField} from '@mui/material';
import Button from '@mui/material/Button';
import {SupabaseClient} from '@supabase/supabase-js';
import {Settings} from '../../types/Supabase';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type WelcomerProps = {
  supabaseClient: SupabaseClient
}

function Welcomer(props: WelcomerProps) {
  let { supabaseClient: supabase } = props;
  
  const getSettings = () => {
    const user = supabase.auth.user();
    if (user && user.user_metadata.settings) {
      return user.user_metadata.settings as Settings;
    } else {
      return {} as Settings;
    }
  };
  const [settings, setSettings] = React.useState<Settings>({
    ...getSettings()
  });
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setSettings((prev: Settings) => {
      return { 
        ...prev,
        [name]:value
      }
    });
  }

  const handleClose = async () => {
    let modals : Array<string> = ["WELCOME_COMPANY"];
    if (settings.shownModals && settings.shownModals.length > 0) {
      modals = [
        ...modals,
        ...settings.shownModals
      ];
    }
    const { error } = await supabase.auth.update({
      data: {
        settings: {
          ...settings,
          companyName: settings.companyName,
          companyPhone: settings.companyPhone,
          companyWhy: settings.companyWhy,
          shownModals: Array.from(new Set(modals))
        }
      }
    })
    if (error) {
      alert(`Error, Please try again ${error}`);
    }
    setOpen(false);
  }
  
  const [open, setOpen] = React.useState(() => {
    const currentSettings = getSettings();
    return (currentSettings && !currentSettings.shownModals) ||
    (currentSettings.shownModals &&
    !currentSettings.shownModals.includes('WELCOME_COMPANY'))
  }
  );

  return (
    <div style={{display: open ? '' : 'none'}}>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="column">
            <Typography id="modal-modal-title" variant="h5" component="h2" style={{paddingBottom:'1rem'}}>
              Welcome to Gift Green
            </Typography>
            <Typography id="modal-modal-title" variant="h6" component="h3">
              Business name:
            </Typography>
            <TextField
              name="companyName"
              style={{padding: "1rem"}}
              id="outlined-number"
              value={settings.companyName || ""}
              inputProps={{
                maxLength: 50,
              }}
              onChange={handleChange}
            />
            <Typography id="modal-modal-title" variant="h6" component="h3">
             Business phone number:
            </Typography>
            <TextField
              name="companyPhone"
              style={{padding: "1rem"}}
              id="outlined-number"
              value={settings.companyPhone || ""}
              inputProps={{
                maxLength: 15,
              }}
              onChange={handleChange}
            />
            <Typography id="modal-modal-title" variant="h6" component="h3">
             Why did you visit our platform today?:
            </Typography>
            <TextField
              name="companyWhy"
              style={{padding: "1rem"}}
              id="outlined-number"
              value={settings.companyWhy || ""}
              onChange={handleChange}
            />

            <div className="row space-between" style={{marginLeft: '1rem', marginRight: '1rem'}}>
              <div></div>
              <Button
                disabled={settings.companyName?.length < 1}
                size="large"
                variant="contained"
                onClick={handleClose}
              >Continue</Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default Welcomer;
