import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from './../components/Menu';
import Create from './../components/Create';
import ViewAll from './../components/ViewAll';
import Subscription from './../components/Subscription';
import Stats from './../components/Stats';
import Settings from './../components/Settings';
import View from './../components/View';
import Tutorial from './../components/Tutorial';
import { SupabaseClient } from '@supabase/supabase-js'
import { PageTypes } from './../types/PageTypes';
import { Titles } from './../types/Titles';
import { useLocalStorage } from './../utils/useLocalStorage'; 
import Welcomer from '../components/modals/Welcomer';
import ErrorWindow from '../components/modals/ErrorWindow';

const drawerWidth = 240;

type LayoutProps = {
  supabaseClient: SupabaseClient;
  window: any;
};

function ResponsiveDrawer(props: LayoutProps) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [page, setPage] = useLocalStorage('pagev1', PageTypes.Tutorial);
  const [code, setCode] = useLocalStorage('codev1', '');

  const [errorModalOpen, setErrorModalOpen] = React.useState(false);
  const [appError, setAppError] = React.useState('');
  const handleClearError = () => {
    setErrorModalOpen(false);
    setAppError('');
  };
  
  const handleError = (error: string) => {
    setErrorModalOpen(true);
    setAppError(error)
  }

    const updatePage = (p: PageTypes) => {
        setPage(p);
        setMobileOpen(false);
    }


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getPage = () => {
    switch (page) {
      case PageTypes.Create:
        return (<Create {...props} setPage={updatePage} setCode={setCode} code={code} handleError={handleError}/>)
      case PageTypes.View:
        return (<View {...props} setPage={updatePage} setCode={setCode} code={code} handleError={handleError} />)
      case PageTypes.ViewAll:
        return (<ViewAll {...props} setPage={updatePage} setCode={setCode} code={code}/>)
      case PageTypes.Stats:
        return (<Stats {...props} setPage={updatePage} setCode={setCode} code={code}/>)
      case PageTypes.Subscription:
        return (<Subscription {...props} setPage={updatePage} setCode={setCode} code={code}/>)
      case PageTypes.Settings:
        return (<Settings {...props} setPage={updatePage} setCode={setCode} code={code} handleError={handleError} />)
      case PageTypes.Tutorial:
        return (<Tutorial/>)
      default:
        return (<ViewAll {...props} setPage={updatePage} setCode={setCode}  code={code}/>)
    }
  }

  const drawer = (
    <Menu {...props} setPage={updatePage} />
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <Welcomer {...props}/>
      <ErrorWindow open={errorModalOpen} handleClose={handleClearError} error={appError} />
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {Titles[page]}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {getPage()}
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
