import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type ErrorWindowProps = {
  open: boolean, 
  handleClose: any,
  error: string
}

function ErrorWindow(props: ErrorWindowProps) {
  let { open, handleClose, error } = props;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="column">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Error:
            </Typography>
            <Typography id="modal-modal-title" variant="body1" style={{padding:'1rem'}}>
              {error}
            </Typography>
            <div className="row space-between">
              <div></div>
            <Button data-umami-event="closeError" variant="contained" onClick={handleClose}>Continue</Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ErrorWindow;
