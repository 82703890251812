import * as React from 'react';
import { SupabaseClient } from '@supabase/supabase-js';
import SupaGrid from './SupaGrid';
import {TextField} from '@mui/material';
import {LocalizationProvider, MobileDatePicker} from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import {PageTypes} from '../types/PageTypes';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

type ViewAllProps = {
  setCode: Function;
  setPage: Function;
  code: string;
  supabaseClient: SupabaseClient;
};

function ViewAll(props: ViewAllProps) {
  const { setCode, setPage, supabaseClient: supabase } = props;
  const [query, setQuery] = React.useState(supabase.from('codes').select('*', {count: 'exact'}).is('draft', false));

  const [start, setStart] = React.useState<Date>(new Date('2022'));
  const [end, setEnd] = React.useState<Date>(new Date());
  const [search, setSearch] = React.useState<String>('');

  React.useEffect(() => {
    let q = supabase
      .from('codes')
      .select("*", {count: "exact"})
      .gte('created_at', start.toISOString())
      .lte('created_at', end.toISOString());
    if (search) {
      q.like('code', '%'+search+'%');
    }
    setQuery(q);
  }, [start, end, search, supabase])
  const isPhone = window.screen.width < 550; 
  let giftCodeColumns : any = [
    {field: 'code', headerName: 'Code', width: isPhone ? 200 : 130}
  ]
  if (!isPhone) {
    giftCodeColumns = [
      ...giftCodeColumns,
      {field: 'amount', headerName: 'Amount',
      valueFormatter: (params: { value: string }) => {
          if (params.value) {
            return "$" + params.value;
          }
      }},
      {field: 'created_at', headerName: 'Date', width: 200,
        valueFormatter: (params: { value: string }) => {
          if (params.value) {
            const date = new Date(params.value)
            return date.toLocaleString('en-GB');
          }
        }, 
      },
      {field: 'details', headerName: 'Details'},
      {field: 'custom_message', headerName: 'Custom Message'},
    ];
  }
  giftCodeColumns.push(
    {
      field: "Action",
      renderCell: () => {
        return (
          <Button
            variant="contained"
            color="primary"
          >
            View
          </Button>
        );
      }
    }
  );
  type Row = {
    id: number,
    created_at: Date,
    code: string,
    amount: number
  }

  function handleRowClick(row: Row) {
    setCode(row.code);
    setPage(PageTypes.View);
  }

  return (
    <>
      <div style={{maxWidth: "50rem"}}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <TextField
              id="outlined-adornment-code"
              variant="outlined"
              value={search}
              onClick={(e) => e.stopPropagation() }
              onChange={(event) => {
                event.stopPropagation();
                setSearch(event.target.value.toUpperCase())
              }}
              label="Search Gift Code"
            />
            <Typography style={{padding:"1rem"}}>Filter by date</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <div style={{padding:"0.5rem"}}>
                <MobileDatePicker
                  label="Start"
                  inputFormat="MM/dd/yyyy"
                  value={start}
                  onChange={(newValue) => {
                    if (newValue) {
                      setStart(newValue);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
              <div style={{padding:"0.5rem"}}>
                <MobileDatePicker
                  label="End"
                  inputFormat="MM/dd/yyyy"
                  value={end}
                  onChange={(newValue) => {
                    if (newValue) {
                      setEnd(newValue);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </LocalizationProvider>
          </AccordionDetails>
        </Accordion>
      </div>
      <div style={{height: 400, maxWidth: '50rem'}}>
        <SupaGrid 
          columns={giftCodeColumns}
          query={query}
          limit={6}
          handleRowClick={handleRowClick}
        />
      </div>
    </>
  )
}

export default ViewAll;
