import * as React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@material-ui/icons/Add';
import { DataGrid } from '@mui/x-data-grid';
import { SupabaseClient } from '@supabase/supabase-js';
import { Code,/* Transaction*/ } from './../types/Supabase';
import Transactions from './../components/modals/Transactions';
import Button from '@mui/material/Button';

type ViewProps = {
  setCode: Function;
  setPage: Function;
  code: string;
  supabaseClient: SupabaseClient;
  handleError(str: string): any;
};

function View(props: ViewProps) {
  //TODO enable when viewed
  const code = props.code;
  const setCode = props.setCode;
  const supabase = props.supabaseClient;
  const handleError = props.handleError;
  const [values, setValues] = React.useState({
    id: 0,
    amount: 0,
    details: '',
    transactions: [{id: 0}],
  });
  const [loading, setLoading] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);

  // Select Data
  React.useEffect(() => {
    (async () => {
      // Make not draft
      await supabase
	.from('codes')
	.update({ draft: 0 })
	.match({ code });
      let { data: codeData, error } = await supabase
	.from<Code>('codes')
	.select(`
	  *,
	  transactions (
	    *
	  )
      `).eq('code', code).limit(1).single();
      if (error) {
	handleError(`Error: ${error}`)
      } else if (codeData) {
	console.log('Code Data', codeData);
	let amount = codeData.amount;
	codeData.transactions.map(e=> amount += e.amount)
	setValues({
	  id: codeData.id || 0,
	  amount: amount || 0,
	  details: codeData.details || "",
	  transactions: codeData.transactions.reverse(),
	});
      }
      if (loading) {
	setLoading(false);
      }
    })();
  }, [code, supabase, loading, handleError]);

  const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
    handleUpdate();
  };

  const handleUpdate = async () => {
    const { error } = await supabase
      .from('codes')
      .update({'details': values.details}).eq('id',values.id);
    if (error) {
      handleError(`Error, Please try again ${error}`);
    }
  }

  const transactionsColumns : any = [
    {
      field: 'id',
      headerName: 'ID',
    },
    {
      field: 'amount',
      headerName: 'Amount',
      valueFormatter: (params: { value: string }) => {
          if (params.value) {
            return "$" + params.value;
          }
      },
    },
    {
      field: 'created_at',
      headerName: 'Date',
      valueFormatter: (params: any) => {
        if (params.value) {
          const date = new Date(params.value)
          return date.toLocaleString('en-GB');
        }
      },
      width: 300,
    }
  ];

  const openTransactionPopup = async () => {
    setOpenPopup(true);
    console.log("Open");
  }
  const handleCloseTrasactionWindow = async () => {
    setOpenPopup(false);
    setCode(code+''); // Trigger reload
    setLoading(true);
    console.log("close");
  }

  return (
    <div className="column space-between max-width-smaller">
      <Typography component="div" className="row" variant="h4">Code: {code}</Typography>
      <Typography component="div" className="row" variant="h5">Balance: ${values.amount}</Typography>
      <div className="padding-top">
	<div className="row space-between">
	  <Typography variant="h6" style={{marginTop: '0.3rem'}}>Transactions</Typography>
	  <Button variant="contained" endIcon={<AddIcon />} onClick={openTransactionPopup}>
	    Add Transaction
	  </Button>
	</div>
	<div style={{height: 400, paddingTop:'1rem'}}>
	  <DataGrid
	    rows={values.transactions}
	    columns={transactionsColumns}
	    pageSize={5}
	    rowsPerPageOptions={[5]}
	  />
	</div>
      </div>
      <TextField
	style={{marginTop: '1rem'}}
	id="outlined-multiline-static"
	label="Details"
	value={values.details}
	onChange={handleChange('details')}
	multiline
	rows={4}
	defaultValue="Details"
      />
      <Transactions open={openPopup} handleClose={handleCloseTrasactionWindow} supabaseClient={supabase} codeId={values.id}/>
    </div>
  )
}

export default View;
