import * as React from 'react';
import { SupabaseClient } from '@supabase/supabase-js';
import {Alert, Button, TextField} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import {Settings as SettingsType} from '../types/Supabase';

type SettingsProps = {
  setCode: Function;
  setPage: Function;
  code: string;
  supabaseClient: SupabaseClient;
  handleError(str: string): any;
};

function Settings(props: SettingsProps) {
  const [saveAlert, setSaveAlert] = React.useState(false);
  const { supabaseClient: supabase, handleError } = props;

  const getSettings = () => {
    const user = supabase.auth.user();
    if (user && user.user_metadata.settings) {
      return user.user_metadata.settings as SettingsType;
    } else {
      return {} as SettingsType;
    }
  };

  const [settings, setSettings] = React.useState<SettingsType>({
    ...getSettings()
  });

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setSettings((prev: SettingsType) => {
      return { 
        ...prev,
        [name]:value
      }
    });
    (async () => {
      const { error } = await supabase.auth.update({
        data: { 
          settings: {
            ...settings,
            [name]: value
          }
        }
      })
      if (error) {
        handleError(`Error, Please try again ${error}`);
      }
    })();
  }

  function handleSave() {
    setSaveAlert(true);
  }

  return (
    <>
      <div className="column space-between max-width-smaller form-wrapper">
        <Alert style={{display: saveAlert ? '' : 'none'}} onClose={() => {setSaveAlert(false)}}>Saved</Alert>
        <TextField
	  style={{marginTop:'1rem'}}
          id="outlined-required"
          label="Company Name"
          name="companyName"
          inputProps={{
            maxLength: 50,
          }}
          value={settings.companyName}
          onChange={handleChange}
        />
        <Button style={{marginTop:'1rem'}} variant="contained" endIcon={<SaveIcon />} onClick={handleSave}>
          Save
        </Button>
      </div>
     </>
  )
}

export default Settings;
