import * as React from 'react';
function Tutorial() {
  return (
    <div className="column space-between max-width-smaller form-wrapper">
    <div style={{ position: 'relative', paddingBottom: '47.8125%', height: '0' }}>
        <iframe
        title="Tutorial"
          src="https://www.loom.com/embed/7a8355e4975e483b97d34ac9452cd602?sid=fb7c31ab-2b78-485d-aa57-5304c7d9868a"
          frameBorder="0"
          style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
        ></iframe>
      </div>
      <div>
      <h1>How to Use:</h1>

      <h2>1. Create a Gift Card</h2>
      <ul>
        <li>Click on <strong>Create</strong> to start.</li>
        <li>Set the <strong>Amount</strong> (e.g., $20).</li>
        <li>Choose how to send it: <strong>Phone Number</strong> or <strong>Email</strong> (or both).</li>
        <li>Add a <strong>Custom Message</strong> (e.g., “Enjoy a free car wash!”).</li>
        <li>Optionally, add <strong>Internal Notes</strong> (e.g., “For Bob”).</li>
        <li>Click <strong>Create</strong> to generate a unique code and send it to the recipient.</li>
      </ul>

      <h2>2. Track and Manage Gift Cards</h2>
      <ul>
        <li>Go to <strong>View All</strong> to see all issued gift cards.</li>
        <li>Search for a specific code if needed.</li>
        <li>Record transactions:
          <ul>
            <li>Start with an initial balance (e.g., $25).</li>
            <li>Add or subtract amounts as transactions occur (e.g., add $1, spend $10).</li>
            <li>Update the balance accordingly (e.g., $16 remaining).</li>
          </ul>
        </li>
      </ul>

      <h2>3. Need Help or Have Suggestions?</h2>
      <ul>
        <li>Contact us through the <strong>Feedback</strong> or <strong>Report Bug</strong> section for assistance or feature requests.</li>
      </ul>
    </div>    

    </div>
  )
}

export default Tutorial;
