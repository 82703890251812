import * as React from 'react';
import { SupabaseClient } from '@supabase/supabase-js';

type StatsProps = {
  setCode: Function;
  setPage: Function;
  code: string;
  supabaseClient: SupabaseClient;
};

function Stats(props: StatsProps) {
  //TODO Stats
   return (
     <>
       Statistics coming soon
     </>
  )
}

export default Stats;
