import * as React from 'react';
import './styles/Main.css'
import { Auth } from '@supabase/ui'
import { createClient, SupabaseClient } from '@supabase/supabase-js'
import Main from './layout/Main';
import {createTheme, ThemeProvider} from '@mui/material';

const supabase: SupabaseClient = createClient('https://uunobpvowkapyjmihfea.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJvbGUiOiJhbm9uIiwiaWF0IjoxNjQyMjU2NDg5LCJleHAiOjE5NTc4MzI0ODl9.s0WGF3sjBLfmMUqJQywuWwHiouOXi0r0PXxMJWCpC7Y')

type AppProps = {
  supabaseClient: SupabaseClient;
  window?: any;
  children?: any;
};
const mainTheme = createTheme({
  palette: {
    primary: {
      main: '#34a283',
    },
  },
});

const Container = (props: AppProps) => {
  const { user } = Auth.useUser();

  if (user)
    return (
      <>
        <Main {...props} />
      </>
    )
  return props.children
}


function App() {
  return (
    <ThemeProvider theme={mainTheme}>
    <Auth.UserContextProvider supabaseClient={supabase}>
      <Container supabaseClient={supabase} >
        <div className="row space-around">
          <div className="max-width">
            <img src="logo.png" alt="Company logo" style={{maxWidth:'16rem'}}/>
            <Auth supabaseClient={supabase} />
            <div style={{padding: '4.5rem', fontSize:'0.75rem', textAlign: 'center'}}>
              By signing up you agree to our<br></br>
              <a href="https://giftgreen.co/terms.html">terms of service and privacy policy</a>
            </div>
          </div>
        </div>
      </Container>
    </Auth.UserContextProvider>
    </ThemeProvider>
  )
}

export default App;
