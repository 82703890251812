import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import SettingsIcon from '@material-ui/icons/Settings';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddIcon from '@material-ui/icons/Add';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import BugReportIcon from '@material-ui/icons/BugReport';
import RateReviewIcon from '@material-ui/icons/RateReview';
//import SearchIcon from '@material-ui/icons/Search';
import TableChartIcon from '@material-ui/icons/TableChart';
//import PaymentIcon from '@material-ui/icons/Payment';
import { SupabaseClient } from '@supabase/supabase-js'
import { PageTypes } from './../types/PageTypes';

type MenuProps = {
  setPage: Function;
  supabaseClient: SupabaseClient;
};

function Menu(props: MenuProps) {
  const setPage = props.setPage;
  return (
    <div>
      <Toolbar>
	<img alt="logo" src="logo.png" style={{maxWidth: '10rem'}}/>
      </Toolbar>
      <Divider />
      <List>
        <ListItem button data-umami-event="createMenu" key={"Create"} onClick={() => setPage(PageTypes.Create)}>
	  <ListItemIcon>
	    <AddIcon />
	  </ListItemIcon>
	  <ListItemText primary={"Create"} />
	</ListItem>
    {/*
          <ListItem button data-umami-event="" key={"Find"} onClick={() => setPage(PageTypes.Find)}>
	  <ListItemIcon>
	    <SearchIcon />
	  </ListItemIcon>
	  <ListItemText primary={"Find"} />
	</ListItem>
      */}
        <ListItem button data-umami-event="ViewMenu" key={"View All"} onClick={() => setPage(PageTypes.ViewAll)}>
	  <ListItemIcon>
	    <TableChartIcon />
	  </ListItemIcon>
	  <ListItemText primary={"View All"} />
	</ListItem>
      {/*
	<ListItem button data-umami-event="" key={"Statistics"} onClick={() => setPage(PageTypes.Stats)}>
	  <ListItemIcon>
	    <EqualizerIcon />
	  </ListItemIcon>
	  <ListItemText primary={"Statistics"} />
	</ListItem>
      */}
      </List>
      <Divider />
      <List>
	<ListItem button data-umami-event="tutorialMenu" key={"Tutorial"} onClick={() => setPage(PageTypes.Tutorial)}>
	  <ListItemIcon>
	    <MenuBookIcon />
	  </ListItemIcon>
	  <ListItemText primary={"Tutorial"} />
	</ListItem>
	<ListItem button data-umami-event="feedbackMenu" key={"Feedback"} onClick={() => window.open("https://app.formbricks.com/s/cm15m3ssi000d35dxatk551b1")}>
	  <ListItemIcon>
	    <RateReviewIcon />
	  </ListItemIcon>
	  <ListItemText primary={"Feedback"} />
	</ListItem>
	<ListItem button data-umami-event="reportBugMenu" key={"Report Bug"} onClick={() => window.open("https://app.formbricks.com/s/cm15m560p0002102q7p13qaal")}>
	  <ListItemIcon>
	    <BugReportIcon />
	  </ListItemIcon>
	  <ListItemText primary={"Report Bug"} />
	</ListItem>
	<ListItem button data-umami-event="settingsMenu" key={"Settings"} onClick={() => setPage(PageTypes.Settings)}>
	  <ListItemIcon>
	    <SettingsIcon />
	  </ListItemIcon>
	  <ListItemText primary={"Settings"} />
	</ListItem>
      </List>
      {/*
      <Divider />
      <List>
        <ListItem button data-umami-event="" key={"Subscription"} onClick={() => setPage(PageTypes.Subscription)}>
            <ListItemIcon>
              <PaymentIcon />
            </ListItemIcon>
            <ListItemText primary={"Subscription"} />
          </ListItem>
      </List>
	*/}
      <Divider />
      <List>
        <ListItem button data-umami-event="logoutMenu" key={"Logout"} onClick={() => props.supabaseClient.auth.signOut()}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItem>
      </List>
    </div>
  )
}

export default Menu;
