import * as React from 'react';
import { SupabaseClient } from '@supabase/supabase-js'
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import ShortUniqueId from 'short-unique-id';
import {Code} from '../types/Supabase';
import PhoneInput from 'react-phone-input-2';
import '../styles/PhoneInput.css';
import {PageTypes} from '../types/PageTypes';
import {InputLabel} from '@material-ui/core';

type CreateProps = {
  setCode: Function,
  setPage: Function,
  code: string,
  supabaseClient: SupabaseClient,
  handleError(error: string): any,
}

function Create(props: CreateProps) {
  const { setCode, setPage, code, supabaseClient: supabase, handleError } = props;
  const [loading, setLoading] = React.useState(false);
  const [ codeGenerated, setCodeGenerated ] = React.useState(false);
  const [values, setValues] = React.useState({
    amount: '',
    details: '',
    phone: '',
    email: '',
    country: 'US',
    custom: ''
  });
  const uid = new ShortUniqueId({
    dictionary: ['2','3','4','5','6','7','8','9','A','B','C','D','E','F','G','H','J','K','M','N','P','Q','R','S','T','U','V','W','X','Y','Z'],
    length: 7
  });
  const handleChange = (prop: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handlePhoneChange = (
    phone: string, 
    country: {countryCode: string}
  ) => {
    setValues({ ...values, phone, country: country.countryCode });
  };

  const generateCodes = async () => {
    const codes : Array<string> = [""].fill(uid() as string, 0, 10);
    let { data, error } = await supabase
      .from<Code>('codes')
      .select("code", {count: "exact"})
      .in('code', codes);
    if (data && !error) {
      const validCodes = codes.filter((e) => { 
	return !data?.map((j) => j.code).includes(e)
      });
      if (validCodes.length) {
	return validCodes[0];
      } else {
	return false;
      }
    }
  }

  const createGiftCode = async () : Promise<string> => {
    return new Promise(async (resolve, reject) => {
      if (!codeGenerated) {
	const generatedCode = await generateCodes();
	if (!generatedCode) {
	  return reject('Failed to create gift code, please try again');
	}

	const { data, error } = await supabase
	  .from<Code>('codes')
	  .insert([
	    { amount: Number(values.amount), custom_message: values.custom, details: values.details, code: generatedCode },
	  ]);

	if (error) {
	  return reject(`Error, Please try again. ${error}`);
	} else if (data) {
	  console.log('Created', generatedCode);
	  setCode(generatedCode);
	  setCodeGenerated(true);
	  resolve(generatedCode);
	}
      } else {
	return resolve(code);
      }
    });
  }

  const sendGiftCode = async (generatedCode: string) => {
    return new Promise(async (resolve, reject) => {
      const session = supabase.auth.session();
      const api = (window.location.hostname === 'localhost') ?
	'http://localhost:3010/send' :
	'https://api.giftgreen.co/send';
      await fetch(api, {
	headers: {
	  'Accept': 'application/json',
	  'Content-Type': 'application/json'
	},
	body: JSON.stringify({
	  email: values.email,
	  phone: values.phone,
	  code: generatedCode,
	  country: values.country,
      custom_message: values.custom,
	  session: session?.access_token
	}),
	referrer: "",
	mode: 'cors',
	method: 'POST',
      }).then(async res => {
	// Handle Response
	if (res.ok) {
	  resolve(setPage(PageTypes.View));
	} else {
	  try { 
	    const error = await res.json();
	    if (error.error) {
	      reject(`Error: ${error.error}`);
	    } else {
	      reject('Failed to send coupon. Please try again later');
	    }
	  } catch (e) {
	    reject('Failed to send coupon. Please try again later');
	  }
	}
      }).catch(() => { reject('Failed to send coupon. Please try again later') })
    });
  }

  const handleCreate = async () => {
    setLoading(true);
    if (!values.amount) {
      setLoading(false);
      return handleError("Please set an amount");
    }
    console.log("Creating ...");
    try {
      const generatedCode = await createGiftCode();
      if ((values.phone !== '' && values.phone.length > 1) || values.email !== '') {
	await sendGiftCode(generatedCode);
      } else {
	console.log('skip sending');
	setPage(PageTypes.View);
      }
    } catch (e) {
      handleError(e as string);
    }
    setLoading(false);
  }

  return (
    <div className="column space-between max-width-smaller form-wrapper">
      <InputLabel shrink htmlFor="outlined-adornment-amount">
	Amount - <i>Required</i>
      </InputLabel>
      <TextField
	required
	id="outlined-adornment-amount"
	variant="outlined"
	value={values.amount}
	onChange={handleChange('amount')}
	label="Amount"
      />
      <InputLabel shrink htmlFor="PhoneInput">
	Phone - <i>Optional</i>
      </InputLabel>
      <PhoneInput
	country={'us'}
	onlyCountries={['us','ca']}
	value={values.phone}
	onChange={handlePhoneChange}
      />
      <InputLabel shrink htmlFor="outlined-adornment-email">
	Email - <i>Optional</i>
      </InputLabel>
      <TextField
	style={{marginTop:'1rem'}}
	id="outlined-adornment-email"
	variant="outlined"
	value={values.email}
	onChange={handleChange('email')}
	label="Email"
      />
      <InputLabel shrink htmlFor="outlined-adornment-custom">
	Custom Message - <i>Optional</i>
      </InputLabel>
      <TextField
	style={{marginTop:'1rem'}}
	id="outlined-multiline-static-details"
	label="Custom"
	value={values.custom}
	onChange={handleChange('custom')}
	multiline
	rows={4}
    inputProps={{ maxLength: 50 }} // Set maximum length here
	defaultValue="Free Car Wash"
      />
      <InputLabel shrink htmlFor="outlined-adornment-details">
	Internal Notes - <i>Optional</i>
      </InputLabel>
      <TextField
	style={{marginTop:'1rem'}}
	id="outlined-multiline-static-details"
	label="Notes"
	value={values.details}
	onChange={handleChange('details')}
	multiline
	rows={4}
	defaultValue="Details"
      />
      <LoadingButton data-umami-event="createButton" loading={loading} style={{marginTop:'1rem'}} variant="contained" endIcon={<SendIcon />} onClick={handleCreate}>
	Create
      </LoadingButton>
      <p style={{fontSize: '0.85rem', paddingTop:'0.25rem'}}>Note: Sending limit of 20 an hour in free trial</p>
    </div>
  )
}

export default Create;
