import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {TextField} from '@mui/material';
import Button from '@mui/material/Button';
import {SupabaseClient} from '@supabase/supabase-js';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type PopupType = "add"|"sub";

type TransactionProps = {
  open: boolean, 
  handleClose: any, 
  codeId: number,
  supabaseClient: SupabaseClient
}

function Transactions(props: TransactionProps) {
  let { open, handleClose, codeId, supabaseClient: supabase } = props;

  const [transactionAmount, setTransactionAmount] = React.useState('0');

  async function handleTransaction(type: PopupType) {
    if (isNaN(Number(transactionAmount))) { alert('Invalid Number'); }
    if (isNaN(Number(transactionAmount))) { return handleClose() }
    const absolute = Math.abs(Number(transactionAmount));
    const signed = (type === 'add') ? absolute : absolute*-1;
    const { error } = await supabase
      .from('transactions')
      .insert([
        { amount: signed, code_id: codeId },
      ]);
    if (error) {
      alert(`Error, Please try again ${error}`);
    } else {
      setTransactionAmount('0');
      handleClose();
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="column">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add Transaction
            </Typography>
            <TextField
              style={{padding: "1rem"}}
              id="outlined-number"
              type="number"
              value={transactionAmount}
              onChange={(event) => setTransactionAmount(event.target.value)}
            />
            <div className="row space-between" style={{marginLeft: '1rem', marginRight: '1rem'}}>
              <Button
                size="large"
                style={{}}
                variant="contained"
                onClick={() => handleTransaction('sub')}
                color='error'
              >Subtract</Button>
              <Button
                size="large"
                style={{}}
                variant="contained"
                onClick={() => handleTransaction('add')}
                color='success'
              >Add</Button>

            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default Transactions;
