import * as React from 'react';
import { DataGrid/* GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector */ } from '@mui/x-data-grid';
//import { createSvgIcon } from '@mui/material/utils';
//import Button from '@mui/material/Button';

type SupaGridProps = {
  columns: Array<any>,
  limit: number,
  query: any,
  handleRowClick?: Function
};

/*
const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  'SaveAlt',
);
 */

function SupaGrid(props: SupaGridProps) {
  const { 
    columns = [{field: 'id', headerName: 'ID'}], 
    limit = 20, 
    query,
    handleRowClick = () => {}
  } = props;
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(limit);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [totalRows, setTotalRows] = React.useState(0);
  (async () => {
    let q = Object.assign(Object.create(Object.getPrototypeOf(query)), query);
    const { count } = await q;
    setTotalRows(count);
  })();

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      let q = Object.assign(Object.create(Object.getPrototypeOf(query)), query);
      let { data: newRows, error } = await q.range(page*pageSize, page*pageSize+pageSize);
      if (error) {
        console.error("Query Error", error);
        newRows = [];
      }
      setRows(newRows);
      setLoading(false)
    })();
  }, [page, pageSize, query]);

  /*
  async function handleExport() {
    let q = Object.assign(Object.create(Object.getPrototypeOf(query)), query);
    let { data: allRows, error } = await q.limit(totalRows);
    if (error) {
      console.log("Error Exporting Rows", error);
      alert("Export failed");
    }
    const csvContent = "data:text/csv;charset=utf-8,"
      + allRows.map((e:any) => Object.values(e).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "export.csv");
    document.body.appendChild(link);
    link.click();
  }*/

  /*
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <Button
          color={'primary'}
          size={'small'}
          startIcon={<ExportIcon />}
          onClick={() => handleExport()}>
          Export to CSV
        </Button>
      </GridToolbarContainer>
    );
  }
   */

  return (
    <div style={{height: '100%', width: '100%'}}>
      <DataGrid
        {...props}
        columns={columns}
        pagination
        rowCount={totalRows}
        rows={rows}
        loading={loading}
        page={page}
        pageSize={pageSize}
        paginationMode="server"
        onRowClick={(row) => handleRowClick(row.row)}
        onPageChange={(p) => setPage(p)}
        onPageSizeChange={(ps) => setPageSize(ps)}
        /*components={{ Toolbar: CustomToolbar }}*/
      />
    </div>
  )
}

export default SupaGrid;
